import { FC } from "react"
import { useI18n } from "next-localization"

import styles from "./product-card-label.module.scss"

type Props = {
  discount: number | null
  productType: string
}

const LABEL_PRODUCTS = ["photo-book", "mini-book"]

const ProductCardLabel: FC<Props> = ({ discount, productType }) => {
  const { t } = useI18n()

  const isVisible = LABEL_PRODUCTS.includes(productType)

  return (
    <div className={styles["wrapper"]}>
      {!!discount && <div className={styles["discount-label"]}>-{discount}%</div>}
      {isVisible && !discount && <div className={styles["label"]}>{t("product.photobook.label")}</div>}
    </div>
  )
}

export { ProductCardLabel }
