import React, { FunctionComponent, ReactElement } from "react"
import { getCaptionFontSize } from "@book-editor-v2/products/mini-book/utils"
import clsx from "clsx"

import { MINI_BOOK_PRODUCT_CAPTION_MAX_LETTER } from "@app/features/product/constants"

import styles from "./product-caption.module.scss"

type TProductCaptionProps = {
  galleryName?: string
  caption?: string[]
  isFixed?: boolean
  className?: string
}

const ProductCaption: FunctionComponent<TProductCaptionProps> = ({
  galleryName,
  caption,
  isFixed,
  className,
}): ReactElement => {
  const ref = React.useRef<HTMLDivElement | null>()
  const captionText = galleryName ? galleryName.substring(0, MINI_BOOK_PRODUCT_CAPTION_MAX_LETTER) : ""
  const countLetter = galleryName ? (galleryName.length > 20 ? 190 : 240) : caption.join(" ").length > 20 ? 190 : 240

  const [fontSize, setFontSize] = React.useState(5)
  const setCaptionFontSize = () => {
    setFontSize(getCaptionFontSize(ref, countLetter))
  }

  React.useEffect(() => {
    setCaptionFontSize()
    window.addEventListener("resize", setCaptionFontSize)

    return () => window.removeEventListener("resize", setCaptionFontSize)
  }, [])

  return (
    <div ref={ref} className={clsx(styles["root"], className)}>
      <div className={styles["caption"]}>
        <div
          className={clsx(styles["caption-text"], styles[`caption-inner-${countLetter}`], {
            [styles["fixed"]]: isFixed,
          })}
          style={{ color: "#111111", fontSize: `${fontSize}px` }}
        >
          {caption && caption[0]}
          {caption && caption.length > 1 && (
            <>
              <br />
              {caption[1]}
            </>
          )}
          {!caption && captionText}
        </div>
      </div>
    </div>
  )
}

export { ProductCaption }
