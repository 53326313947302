import React, { FunctionComponent, ReactElement } from "react"
import { useI18n } from "next-localization"
import cx from "clsx"

import { useGallery } from "@app/contexts"
import { getCoverPositionInlineStyle } from "@app/features/cover/utils"
import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"
import { ProductCaption } from "@app/features/product/components/product-caption"
import { RatingInfo } from "@app/features/product/components/rating-info"
import { LockedModal } from "@app/features/products/components/locked-modal"
import { Price } from "@app/ui/price/price"

import { ProductCardLabel } from "../product-card-label"

import styles from "./product-card.module.scss"

type TProductCardProps = {
  link: string
  type: string
  title: string
  price: any
  picture: string
  disabled: boolean
  galleryCoverSrc: string
  reviews: any | null
  coverRatio?: string
  onClick?: (galleryId: string) => void
  galleryName?: string
  className?: string
}

const ProductCardImprovedWithCover: FunctionComponent<TProductCardProps> = ({
  title,
  reviews,
  type,
  link,
  price,
  picture,
  galleryCoverSrc,
  coverRatio = "",
  disabled = false,
  galleryName,
  onClick = () => {},
  className,
}): ReactElement => {
  const { t } = useI18n()

  const { gallery } = useGallery()

  const coverPositionSettings = gallery?.settings?.coverPosition || null
  const coverPositionStyles = coverPositionSettings !== null ? getCoverPositionInlineStyle(coverPositionSettings) : {}

  const [lockedModalVisible, setLockedModalVisible] = React.useState(false)

  const discountPercent = price?.discount && price?.discount?.percent
  const discountMinPrice = price?.discount && price?.discount?.price
  const initialPrice = price?.price
  const currencySymbol = t(`currency.${price?.currency.handle}`)
  const isMiniBook = type === "mini-book"
  const isPhotoBook = type === "photo-book"

  const isMultiplyPrices = isMiniBook || isPhotoBook

  const hasDisplayRating: boolean =
    typeof reviews?.averageRating !== "undefined" && typeof reviews?.count !== "undefined"

  const handleClick = () => {
    if (disabled) {
      setLockedModalVisible(true)
      return false
    }

    onClick(gallery.id)
    document.location.href = `/gallery/${gallery.url}/${link}`
  }

  const [isImageReady, setIsImageReady] = React.useState(false)
  const loadImage = () => {
    setTimeout(() => {
      setIsImageReady(true)
    }, 200) // =( приходиться сделать задержку, не могу найти, где что тормозит в отрисовке, и caption получает неверный размер
  }

  const handleLoadImage = () => {
    if (isMiniBook) loadImage()
  }

  const picturePng = picture.replace(".jpg", ".png")

  return (
    <React.Fragment>
      <div
        onClick={handleClick}
        className={cx(
          styles["root"],
          styles["preview-improved-with-cover"],
          { [styles["disabled"]]: disabled },
          className
        )}
      >
        <ProductCardLabel discount={discountPercent} productType={type} />

        <picture
          className={cx(styles["picture"], styles["picture-with-cover"], { [styles["with-caption"]]: isMiniBook })}
          onLoad={handleLoadImage}
        >
          <div className={cx(styles["picture-preview"], styles[coverRatio])}>
            {galleryCoverSrc && (
              <GoogleImage
                className={cx(styles["cover"], styles[type], styles[`${type}-with-cover`])}
                src={galleryCoverSrc}
                alt={`Обложка ${title}`}
                style={coverPositionStyles}
              />
            )}
            {coverRatio !== null && (
              <img
                className={cx(styles["image"], styles["image-with-cover"])}
                src={`/img/product/list/${type}-with-cover-${coverRatio}${picturePng}`}
                alt={title}
                width="100%"
                height="100%"
              />
            )}

            {galleryName && isMiniBook && isImageReady && (
              <ProductCaption
                galleryName={galleryName}
                className={cx(styles["caption-root"], styles["caption-with-cover"])}
              />
            )}
          </div>
        </picture>

        <div className={styles["content"]}>
          <Price
            isMultiplyPrices={isMultiplyPrices}
            discountMinPrice={discountMinPrice}
            price={initialPrice}
            currencySymbol={currencySymbol}
          />
          <h4 className={styles["title"]}>{title}</h4>
          {hasDisplayRating && (
            <div className={styles["rating"]}>
              <RatingInfo
                rating={reviews.averageRating}
                afterClassName={styles["rating-count"]}
                after={`(${reviews.count})`}
              />
            </div>
          )}
        </div>
      </div>
      <LockedModal isVisible={lockedModalVisible} onClose={() => setLockedModalVisible(false)} />
    </React.Fragment>
  )
}

export { ProductCardImprovedWithCover }
