import { FC } from "react"
import clsx from "clsx"

import { numberFormatPrice } from "@app/features/product/utils"
import { OldPrice } from "@app/ui/old-price"

import styles from "./price.module.scss"

type Props = {
  isMultiplyPrices?: boolean
  discountMinPrice: number
  price: number
  currencySymbol: string
  className?: string
}

const Price: FC<Props> = ({ isMultiplyPrices, discountMinPrice, price, currencySymbol, className }) => {
  return (
    <span className={clsx(styles["price"], className)}>
      {isMultiplyPrices && "от "}
      <strong>{numberFormatPrice(discountMinPrice ?? price)}</strong>
      <strong className={styles["currency"]}>{currencySymbol}</strong>
      {discountMinPrice && (
        <OldPrice className={clsx(styles["old-price"])}>
          <span>{numberFormatPrice(price)}</span>
          <span className={styles["currency"]}>{currencySymbol}</span>
        </OldPrice>
      )}
    </span>
  )
}

export { Price }
